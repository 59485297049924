/* eslint-disable react/jsx-pascal-case */
import { RouteObject } from 'react-router-dom';

import paths from '@core/router/path';

const routes: RouteObject = {
  path: paths.profile,
  async lazy() {
    const DefaultLayout = await import('@core/layouts/Default');
    const ProtectedRoute = await import('@core/components/ProtectedRoute');
    return {
      element: (
        <ProtectedRoute.default>
          <DefaultLayout.default />
        </ProtectedRoute.default>
      ),
    };
  },
  children: [
    {
      path: '',
      async lazy() {
        const ResettableQueryErrorBoundary = await import(
          '@core/components/ResettableQueryErrorBoundary'
        );
        const PageLoadingFallback = await import(
          '@core/components/PageLoadingFallback'
        );
        const Profile = await import('@core/pages/Profile');
        return {
          element: (
            <ResettableQueryErrorBoundary.default
              LoadingFallback={<PageLoadingFallback.default />}
            >
              <Profile.default />
            </ResettableQueryErrorBoundary.default>
          ),
        };
      },
    },
  ],
};

export default routes;
