import { Flex } from 'antd';
import { Spin } from 'ni-ui-kit';

export default function CenteredSpin() {
  return (
    <Flex
      align="center"
      justify="center"
      style={{ height: '100%' }}
      data-test-id="centered-spin"
    >
      <Spin />
    </Flex>
  );
}
