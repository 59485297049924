import 'ni-ui-kit/dist/index.css';

import './styles.scss';

import App from './App';

export default App;

declare global {
  interface Window {
    cy?: any;
  }
}
