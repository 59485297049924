import { t } from 'i18next';
import { CardStatus as CardStatusType } from '@dtos';

export enum CardStatusEnum {
  'active' = 'active',
  'closed' = 'closed',
  'dormant' = 'dormant',
  'blocked' = 'blocked',
  'pendingActivation' = 'pendingActivation',
  'replaced' = 'replaced',
  'pendingClosure' = 'pendingClosure',
  'default' = 'default',
  'expired' = 'expired',
}

export class CardStatus {
  slug: CardStatusEnum;
  label: string;

  constructor(status: CardStatusType) {
    switch (status) {
      case 'Active':
        this.slug = CardStatusEnum.active;
        break;
      case 'Replaced':
        this.slug = CardStatusEnum.replaced;
        break;
      case 'Blocked':
        this.slug = CardStatusEnum.blocked;
        break;
      case 'Closed':
        this.slug = CardStatusEnum.closed;
        break;
      case 'Dormant':
        this.slug = CardStatusEnum.dormant;
        break;
      case 'Pending Closure':
        this.slug = CardStatusEnum.pendingClosure;
        break;
      case 'Pending Activation':
        this.slug = CardStatusEnum.pendingActivation;
        break;
      case 'New':
        this.slug = CardStatusEnum.pendingActivation;
        break;
      case 'Expired':
        this.slug = CardStatusEnum.expired;
        break;
      default:
        this.slug = CardStatusEnum.default;
        break;
    }

    // TODO: should be removed and mapped to the
    // label the we get from BE once implemented
    this.label = t('cards.' + this.slug + 'CardStatusLabel');
  }
}
