import { createContext, PropsWithChildren, useContext } from 'react';

type Roles = {
  roles: string[];
};

type ResourceAccess = {
  [key: string]: Roles;
};

type LogoutOptions = {
  redirectUri: string | undefined;
};

type AuthService = {
  initialized: boolean;
  token: string | undefined;
  login: () => Promise<void>;
  authenticated: boolean | undefined;
  logout: (options?: LogoutOptions) => Promise<void>;
  tokenParsed?: {
    iss?: string;
    sub?: string;
    aud?: string;
    exp?: number;
    iat?: number;
    auth_time?: number;
    nonce?: string;
    acr?: string;
    amr?: string;
    azp?: string;
    session_state?: string;
    realm_access?: Roles;
    resource_access?: ResourceAccess;
    [key: string]: any; // Add other attributes here.
  };
  isTokenExpired: (minValidity?: number) => boolean;
  updateToken: (minValidity: number) => Promise<boolean>;
  tryingToAutoLogin: boolean;
  getToken: () => AuthService['token'];
  getTokenParsed: () => AuthService['tokenParsed'];
};

const AuthContext = createContext<AuthService | null>(null);

export const AuthProvider = ({
  authService,
  children,
}: PropsWithChildren<{ authService: AuthService }>) => (
  // ...

  <AuthContext.Provider value={authService}>{children}</AuthContext.Provider>
);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

export default AuthProvider;
