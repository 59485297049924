import { IProfileDetails, AddressType, IAddress } from '@dtos';

export interface ContactDetails {
  username: string;
  email: string;
  mobilePhone: string;
  workPhone: string;
}

export interface Address {
  addresses: string[];
  addressType?: AddressType;
  city?: string;
  country?: string;
  state?: string;
  zip?: string;
}

export interface PersonalDetails {
  nationality: string;
  dateOfBirth?: Date;
  firstName: string;
  lastName: string;
  placeOfBirth: string;
  title: string;
}

export class UserProfile {
  contactDetails: ContactDetails;
  personalDetails: PersonalDetails;
  addresses: Address[];

  constructor({
    addresses,
    contactDetails,
    personalDetails,
    username,
  }: IProfileDetails) {
    const dateOfBirth = personalDetails?.dateOfBirth
      ? new Date(personalDetails?.dateOfBirth)
      : undefined;

    this.contactDetails = {
      username: username,
      email: contactDetails?.email,
      mobilePhone: contactDetails?.mobilePhone,
      workPhone: contactDetails?.workPhone,
    };
    this.personalDetails = {
      title: personalDetails?.title,
      firstName: personalDetails?.firstName,
      lastName: personalDetails?.lastName,
      nationality: personalDetails?.citizenship,
      dateOfBirth,
      placeOfBirth: personalDetails?.placeOfBirth,
    };

    this.addresses = addressesMapper(
      addresses?.length ? addresses : [{} as IAddress]
    );
  }

  static transferProfileData(profileData: IProfileDetails) {
    return new UserProfile(profileData as IProfileDetails);
  }
}

const addressesMapper = (addresses: IProfileDetails['addresses']) =>
  addresses.map((address) => ({
    addressType: address?.addressType,
    addresses: [
      address?.addressLine1,
      address?.addressLine2,
      address?.addressLine3,
    ].filter((address) => !!address),
    zip: address?.zip,
    city: address?.city,
    country: address?.country,
    state: address?.state,
  }));
