import { ITransaction, TransactionsCategories } from '@dtos';

import { TransactionStatus } from './TransactionStatus';

export class Transaction {
  // name: string;
  icon: string;
  amount: number;
  settlementAmount: number;
  description: string;
  date: Date;
  id: string;
  currency: string;
  settlementCurrency: string;
  merchantName: string;
  status: TransactionStatus;
  merchantCategoryGroup: TransactionsCategories;
  debitCardFlag: 'D' | 'C';

  constructor(data: ITransaction) {
    // this.name = data.name;
    this.icon = data.icon;
    this.amount = data.sourceAmount;
    this.settlementAmount = data.amount;
    this.description = data.description;
    this.date = new Date(data.transactionDate);
    this.id = data.transactionRefId;
    this.currency = data.sourceCurrency;
    this.settlementCurrency = data.currency;
    this.merchantName = data.merchantName;
    this.debitCardFlag = data.debitCardFlag;
    this.merchantCategoryGroup = data.merchantCategoryGroup;
    this.status = new TransactionStatus(data.status);
  }

  static toList(trxs: ITransaction[]) {
    return trxs.map((t) => new Transaction(t));
  }
}
