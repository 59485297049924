import { ThemeConfig } from 'antd';

const niUiKitTheme = {
  colors: {
    main1: '#18181B',
    main2: '#28282A',
    main3: '#38383B',
    main4: '#49494C',
    main5: '#5B5B5E',
    main6: '#6E6E70',
    main7: '#818183',
    main8: '#959597',
    main9: '#A9A9AB',
    main10: '#BEBEBF',
    main11: '#D3D3D4',
    main12: '#E9E9E9',
    main13: '#FFFFFF',
    primary1: '#2EB135',
    primary2: '#49B848',
    primary3: '#5EBF59',
    primary4: '#71C569',
    primary5: '#82CC7A',
    primary6: '#93D38A',
    primary7: '#A3D99B',
    primary8: '#B3E0AB',
    primary9: '#C2E6BC',
    primary10: '#D1ECCC',
    primary11: '#E1F3DD',
    primary12: '#F0F9EE',
    primary13: '#FFFFFF',
    secondary1: '#6D5468',
    secondary2: '#786174',
    secondary3: '#846E7F',
    secondary4: '#907C8B',
    secondary5: '#9C8998',
    secondary6: '#A897A4',
    secondary7: '#B4A6B1',
    secondary8: '#C0B4BD',
    secondary9: '#CCC3CA',
    secondary10: '#D9D1D7',
    secondary11: '#E5E0E4',
    secondary12: '#F2F0F2',
    secondary13: '#FFFFFF',
    tertiary: '#FFFFFF',
    bg1: '#0060A8',
    bg2: '#F6F6F6',
    text1: '#303030',
    text2: '#808080',
    text3: '#B1B1B1',
    text4: '#6D5468',
    text5: '#2EB135',
    text100: '#303030',
    additional1: '#DBDBDB',
    additional2: '#DBDBDB',
    additional3: '#FFFFFF',
    info1: '#3A84F3',
    info2: '#DBDBDB',
    info3: '#DBDBDB',
    error1: '#D3383F',
    error2: '#FF0505',
    error3: '#DBDBDB',
    warning1: '#FBA705',
    warning2: '#DBDBDB',
    warning3: '#DBDBDB',
    success1: '#2EB135',
    success2: '#DBDBDB',
    success3: '#DBDBDB',
    confirmButton: '#2EB135',
    declineButton: '#DBDBDB',
    activeOperation: '#DBDBDB',
    blockedOperation: '#DBDBDB',
    dormantOperation: '#DBDBDB',
    newOperation: '#DBDBDB',
    closedOperation: '#DBDBDB',
    pendingOperation: '#DBDBDB',
    replacedOperation: '#DBDBDB',
  },
};

const theme: ThemeConfig = {
  token: {
    colorPrimary: '#2EB135',
    colorSuccess: '#2EB135',
    colorWarning: '#FBA705',
    colorTextBase: '#303030',
    fontFamily: 'Gotham',
    colorTextTertiary: '#808080',
    colorPrimaryBorder: '#CDCDCE',
    colorPrimaryActive: '#2EB135',
    colorPrimaryHover: '#2EB135',
    colorPrimaryBg: '#F2F5F7',
    colorBgLayout: '#FFF',
    colorIcon: '#6D5468',
  },
  components: {
    Layout: {
      bodyBg: '#f6f6f6',
    },
    Select: {
      optionSelectedBg: '#0F9016',
      controlItemBgHover: '#D5F5D7',
      controlItemBgActiveHover: '#D5F5D7',
    },
    Button: {
      colorPrimaryActive: '#F7F2F6',
      colorLink: '#2EB135',
      colorLinkActive: '#0F9016',
      colorLinkHover: '#0F9016',
    },
  },
};

export default { theme, niUiKitTheme };
