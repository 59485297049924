/* eslint-disable react/jsx-pascal-case */
import { RouteObject } from 'react-router-dom';
import paths from '@core/router/path';
import { CardAvailableActions } from '@models';

const routes: RouteObject = {
  path: paths.myCards,
  async lazy() {
    const DefaultLayout = await import('@core/layouts/Default');
    const ProtectedRoute = await import('@core/components/ProtectedRoute');
    return {
      element: (
        <ProtectedRoute.default>
          <DefaultLayout.default />
        </ProtectedRoute.default>
      ),
    };
  },

  children: [
    {
      path: '',
      async lazy() {
        const MyCards = await import('@core/pages/MyCards');
        const ResettableQueryErrorBoundary = await import(
          '@core/components/ResettableQueryErrorBoundary'
        );
        const PageLoadingFallback = await import(
          '@core/components/PageLoadingFallback'
        );
        return {
          element: (
            <ResettableQueryErrorBoundary.default
              LoadingFallback={<PageLoadingFallback.default />}
            >
              <MyCards.default />
            </ResettableQueryErrorBoundary.default>
          ),
        };
      },

      children: [
        {
          path: ':id',
          children: [
            {
              path: '',
              async lazy() {
                const ActionCard = await import(
                  '@core/pages/MyCards/components/ActionCard'
                );
                const TransactionTabs = await import(
                  '@core/pages/MyCards/components/TransactionTabs'
                );
                return {
                  element: (
                    <>
                      <ActionCard.default />
                      <TransactionTabs.default />
                    </>
                  ),
                };
              },
            },
            {
              path: paths.blockCard,
              async lazy() {
                const ProtectedCardActionsRoute = await import(
                  '@core/pages/MyCards/components/protectedRoutes/ProtectedCardActionsRoute'
                );
                const BlockCard = await import(
                  '@core/pages/MyCards/components/cardActions/BlockCard'
                );
                return {
                  element: (
                    <ProtectedCardActionsRoute.default
                      cardAction={CardAvailableActions['BLOCK']}
                    >
                      <BlockCard.default />
                    </ProtectedCardActionsRoute.default>
                  ),
                };
              },
            },

            {
              path: paths.changePin,
              async lazy() {
                const module = await import(
                  '@core/pages/MyCards/components/cardActions/ChangePin'
                );
                return { Component: module.default };
              },
            },
            {
              path: paths.setPin,
              async lazy() {
                const ProtectedCardActionsRoute = await import(
                  '@core/pages/MyCards/components/protectedRoutes/ProtectedCardActionsRoute'
                );
                const { SetPin } = await import(
                  '@core/pages/MyCards/components/cardActions/SetPin'
                );
                return {
                  element: (
                    <ProtectedCardActionsRoute.default
                      cardAction={CardAvailableActions['SET_PIN']}
                    >
                      <SetPin />
                    </ProtectedCardActionsRoute.default>
                  ),
                };
              },
            },

            {
              path: paths.unBlockCard,
              async lazy() {
                const ProtectedCardActionsRoute = await import(
                  '@core/pages/MyCards/components/protectedRoutes/ProtectedCardActionsRoute'
                );
                const UnBlockCard = await import(
                  '@core/pages/MyCards/components/cardActions/UnBlockCard'
                );
                return {
                  element: (
                    <ProtectedCardActionsRoute.default
                      cardAction={CardAvailableActions['UNBLOCK']}
                    >
                      <UnBlockCard.default />
                    </ProtectedCardActionsRoute.default>
                  ),
                };
              },
            },
            {
              path: paths.replaceCard,
              async lazy() {
                const ProtectedCardActionsRoute = await import(
                  '@core/pages/MyCards/components/protectedRoutes/ProtectedCardActionsRoute'
                );
                const ReplaceCard = await import(
                  '@core/pages/MyCards/components/cardActions/ReplaceCard'
                );
                return {
                  element: (
                    <ProtectedCardActionsRoute.default
                      cardAction={CardAvailableActions['REPLACE']}
                    >
                      <ReplaceCard.default />
                    </ProtectedCardActionsRoute.default>
                  ),
                };
              },
            },

            {
              path: paths.closeCard,
              async lazy() {
                const ProtectedCardActionsRoute = await import(
                  '@core/pages/MyCards/components/protectedRoutes/ProtectedCardActionsRoute'
                );
                const CloseCard = await import(
                  '@core/pages/MyCards/components/cardActions/CloseCard'
                );
                return {
                  element: (
                    <ProtectedCardActionsRoute.default
                      cardAction={CardAvailableActions['CLOSE']}
                    >
                      <CloseCard.default />
                    </ProtectedCardActionsRoute.default>
                  ),
                };
              },
            },
            {
              path: paths.transferMoney,
              async lazy() {
                const ProtectedCardActionsRoute = await import(
                  '@core/pages/MyCards/components/protectedRoutes/ProtectedCardActionsRoute'
                );
                const MoneyTransfer = await import(
                  '@core/pages/MyCards/components/transferMoney'
                );
                return {
                  element: (
                    <ProtectedCardActionsRoute.default
                      cardAction={CardAvailableActions['MONEY_TRANSFER']}
                    >
                      <MoneyTransfer.default />
                    </ProtectedCardActionsRoute.default>
                  ),
                };
              },
            },
            {
              path: paths.cardControl,
              async lazy() {
                const ProtectedCardActionsRoute = await import(
                  '@core/pages/MyCards/components/protectedRoutes/ProtectedCardActionsRoute'
                );
                const CardControl = await import(
                  '@core/pages/MyCards/components/cardControl/index'
                );
                return {
                  element: (
                    <ProtectedCardActionsRoute.default
                      cardAction={CardAvailableActions['CARD_CONTROL']}
                    >
                      <CardControl.default />
                    </ProtectedCardActionsRoute.default>
                  ),
                };
              },
            },
          ],
        },
      ],
    },
  ],
};

export default routes;
