import { useEffect } from 'react';

import defaultInstance from './default-instance';
import { useAuth } from '@providers/AuthProvider';

const HttpClientInterceptor = () => {
  const { logout, getToken, updateToken, isTokenExpired, getTokenParsed } =
    useAuth();

  useEffect(() => {
    if (window?.cy) return () => null;

    const requestAuthInterceptor = defaultInstance.interceptors.request.use(
      (req) => {
        const token = getToken();
        if (token) {
          req.headers['Authorization'] = `Bearer ${token}`;
        }

        return req;
      }
    );

    const refreshTokenInterceptor = defaultInstance.interceptors.request.use(
      async (req) => {
        if (isTokenExpired()) {
          try {
            const tokenParsed = getTokenParsed();
            const minValidity =
              tokenParsed && tokenParsed.exp && tokenParsed.iat
                ? tokenParsed.exp - tokenParsed.iat
                : 300;
            const refreshed = await updateToken(minValidity);
            if (refreshed) {
              // axios interceptors order of execution is reversed as per https://github.com/axios/axios/pull/1041
              // so the above interceptor should run after this one, thus, no need for the
              // following line supposedly
              // req.headers['Authorization'] = `Bearer ${getToken()}`;
              return req;
            } else {
              logout({
                redirectUri: process.env['NX_UI_URI'],
              });
              return Promise.reject();
            }
          } catch (err) {
            return Promise.reject(err);
          }
        }

        return req;
      },
      (err) => Promise.reject(err)
    );

    return () => {
      defaultInstance.interceptors.request.eject(requestAuthInterceptor);
      defaultInstance.interceptors.request.eject(refreshTokenInterceptor);
    };
  }, []);

  return null;
};

export default HttpClientInterceptor;
