import { t } from 'i18next';
import { TransactionStatusType } from '@dtos';

export enum TransactionStatusEnum {
  done = 'done',
  pending = 'pending',
}

export class TransactionStatus {
  slug: TransactionStatusEnum;
  label: string;

  constructor(status: TransactionStatusType) {
    switch (status) {
      case 'done':
        this.slug = TransactionStatusEnum.done;
        break;
      case 'pending':
        this.slug = TransactionStatusEnum.pending;
    }

    this.label = t('transactions.' + this.slug + 'StatusLabel');
  }
}
