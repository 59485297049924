const paths = {
  myCards: '/my-cards',
  profile: '/profile',
  transferAndPay: '/transfer-and-pay',
  support: '/support',
  blockCard: 'block',
  setPin: 'set-pin',
  changePin: 'change-pin',
  unBlockCard: 'unblock',
  replaceCard: 'replace',
  dispute: 'dispute',
  closeCard: 'close',
  transferMoney: 'transfer-money',
  cardControl: 'card-control',
};

export default paths;
