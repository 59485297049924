import defaultInstance from './default-instance';
import HttpClientInterceptor from './HttpClientInterceptor';

// if (!window?.cy) {
//   defaultInstance.defaults.baseURL = process.env.NX_API_BASE_URL;

//   defaultInstance.interceptors.request.use((req) => {
//     if (keycloak.token) {
//       req.headers['Authorization'] = `Bearer ${keycloak.token}`;
//     }

//     return req;
//   });

//   defaultInstance.interceptors.request.use(
//     async (req) => {
//       if (keycloak.isTokenExpired()) {
//         try {
//           const tokenParsed = keycloak.tokenParsed;
//           const minValidity =
//             tokenParsed && tokenParsed.exp && tokenParsed.iat
//               ? tokenParsed.exp - tokenParsed.iat
//               : 300;
//           const refreshed = await keycloak.updateToken(minValidity);
//           if (refreshed) {
//             // defaultInstance interceptors order of execution is reversed as per https://github.com/defaultInstance/defaultInstance/pull/1041
//             // so the above interceptor should run after this one, thus, no need for the
//             // following line supposedly
//             //   req.headers['Authorization'] = `Bearer ${keycloak.token}`;
//             return req;
//           } else {
//             keycloak.logout();
//             return Promise.reject();
//           }
//         } catch (err) {
//           return Promise.reject(err);
//         }
//       }

//       return req;
//     },
//     (err) => Promise.reject(err)
//   );
// }

export default defaultInstance;
export { HttpClientInterceptor };
