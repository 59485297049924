import Keycloak from 'keycloak-js';
import { PropsWithChildren, useState } from 'react';
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';

import { AuthProvider } from '@providers';

const keycloakInstance = window?.cy
  ? new Keycloak() // could be replaced with a mock
  : new Keycloak({
      url: process.env?.['NX_KC_URL'],
      realm: process.env?.['NX_KC_REALM'] || '',
      clientId: process.env?.['NX_KC_CLIENT_ID'] || '',
    });

export const KeycloakAuthProvider = ({ children }: PropsWithChildren) => {
  const [tryingToAutoLogin, setTryingToAutoLogin] = useState(true);
  return (
    <ReactKeycloakProvider
      authClient={keycloakInstance}
      onEvent={(e) => {
        if (e === 'onReady') {
          setTimeout(() => {
            setTryingToAutoLogin(false);
          });
        } else if (e === 'onAuthRefreshError') {
          keycloakInstance.logout({
            redirectUri: process.env['NX_UI_URI'],
          });
        }
      }}
    >
      <AuthProviderImplementation tryingToAutoLogin={tryingToAutoLogin}>
        {children}
      </AuthProviderImplementation>
    </ReactKeycloakProvider>
  );
};

const AuthProviderImplementation = ({
  children,
  tryingToAutoLogin,
}: PropsWithChildren<{
  tryingToAutoLogin: boolean;
}>) => {
  const { keycloak: keycloakInstance, initialized } = useKeycloak();

  return (
    <AuthProvider
      authService={{
        initialized,
        token: keycloakInstance?.token,
        login: keycloakInstance?.login,
        tryingToAutoLogin: tryingToAutoLogin,
        getToken: () => keycloakInstance?.token,
        tokenParsed: keycloakInstance?.tokenParsed,
        updateToken: keycloakInstance?.updateToken,
        authenticated: keycloakInstance?.authenticated,
        isTokenExpired: keycloakInstance?.isTokenExpired,
        getTokenParsed: () => keycloakInstance?.tokenParsed,
        logout: (options: { redirectUri?: string | undefined } | undefined) =>
          keycloakInstance?.logout({
            redirectUri: options?.redirectUri || undefined,
          }),
      }}
    >
      {children}
    </AuthProvider>
  );
};

export { keycloakInstance };
export default KeycloakAuthProvider;
