import { ConfigProvider } from 'antd';
import { PropsWithChildren } from 'react';
import { ConfigProviderProps } from 'antd/es/config-provider';

export default function ThemeProvider({
  children,
  theme = {},
}: PropsWithChildren<ConfigProviderProps>) {
  return (
    <ConfigProvider
      theme={{
        cssVar: true,
        ...theme,
      }}
    >
      {children}
    </ConfigProvider>
  );
}
